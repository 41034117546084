import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'


export default class extends ApplicationController {
    static targets = [ "input", "activeFilter", "inactiveFilter", "allFilter" ]


    connect() {
        this.initializeTomSelect();
        this.activeOnly = false;
        this.inactiveOnly = false;
    }

    disconnect() {
        if (this.tomselect) {
            this.tomselect.destroy();
        }
    }

    filterActive(event) {
        event.preventDefault();
        this.activeOnly = true;
        this.inactiveOnly = false;
        this.updateFilterButtons();
        if (this.tomselect) {
            this.tomselect.clear();
            this.tomselect.clearOptions();
        }
    }

    filterInactive(event) {
        event.preventDefault();
        this.activeOnly = false;
        this.inactiveOnly = true;
        this.updateFilterButtons();
        if (this.tomselect) {
            this.tomselect.clear();
            this.tomselect.clearOptions();
        }
    }

    filterAll(event) {
        event.preventDefault();
        this.activeOnly = false;
        this.inactiveOnly = false;
        this.updateFilterButtons();
        if (this.tomselect) {
            this.tomselect.clear();
            this.tomselect.clearOptions();
        }
    }

    updateFilterButtons() {
        if (this.hasActiveFilterTarget) {
            this.activeFilterTarget.classList.toggle('btn-primary', this.activeOnly);
            this.activeFilterTarget.classList.toggle('btn-default', !this.activeOnly);
        }
        if (this.hasInactiveFilterTarget) {
            this.inactiveFilterTarget.classList.toggle('btn-primary', this.inactiveOnly);
            this.inactiveFilterTarget.classList.toggle('btn-default', !this.inactiveOnly);
        }
        if (this.hasAllFilterTarget) {
            this.allFilterTarget.classList.toggle('btn-primary', !this.activeOnly && !this.inactiveOnly);
            this.allFilterTarget.classList.toggle('btn-default', this.activeOnly || this.inactiveOnly);
        }
    }

    initializeTomSelect() {
        const model = this.element.dataset.model;
        const isRequired = this.inputTarget.hasAttribute('required');

        // if placeholder is set, use it, otherwise use the default
        let placeholder = this.inputTarget.dataset.placeholder ? this.inputTarget.dataset.placeholder : "Suchen";
        let plugins = {};

        let config = {
            plugins: plugins,
            create: false,
            valueField: 'id',
            labelField: 'text',
            searchField: ['text', 'tags'],
            closeAfterSelect: true,
            selectOnTab: true,
            placeholder: placeholder,
            required: isRequired,
            validateOnBlur: true,
            onInvalid: function() {
                this.wrapper.classList.add('ts-error');
            },
            onValid: function() {
                this.wrapper.classList.remove('ts-error');
            }
        };

        if (model === 'jobs') {
            let currentFilter = 'active'; // Standardmäßig aktive Jobs vorselektieren

            // Status-Filter hinzufügen
            const filterHtml = `
                <div class="flex gap-2 mb-2 px-2 py-1">
                    <button type="button" class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer status-filter" data-status="all">
                        Alle
                    </button>
                    <button type="button" class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-gray-300 text-gray-800 hover:bg-gray-200 cursor-pointer status-filter" data-status="active">
                        <span class="w-1.5 h-1.5 rounded-full bg-green-500 mr-1.5"></span>
                        Aktiv
                    </button>
                    <button type="button" class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer status-filter" data-status="inactive">
                        <span class="w-1.5 h-1.5 rounded-full bg-gray-400 mr-1.5"></span>
                        Inaktiv
                    </button>
                </div>
            `;

            // Funktion zum Filtern der Optionen
            const filterOptions = (status, tomselect) => {
                const options = tomselect.options;
                Object.keys(options).forEach(key => {
                    const option = options[key];
                    // Entferne die Option komplett, wenn sie nicht dem Filter entspricht
                    if (status !== 'all' && ((status === 'active' && !option.active) || (status === 'inactive' && option.active))) {
                        tomselect.removeOption(key);
                    }
                });
            };

            config.render = {
                dropdown: function() {
                    return '<div class="ts-dropdown"><div class="ts-dropdown-content">' + filterHtml + '</div></div>';
                },
                option: function(data, escape) {
                    const subtext = data.subtext 
                        ? `<div class="subtext text-xs text-gray-400 truncate">${data.subtext}</div>` 
                        : '';
                    return `<div class="option flex flex-col">
                        <div class="title text-sm">${escape(data.text)}</div>
                        ${subtext}
                    </div>`;
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                },
                no_results: function(data, escape) {
                    return '<div class="no-results p-2 text-sm text-gray-600">Keine Ergebnisse für "' + escape(data.input) + '"</div>';
                }
            };

            // Event-Handler für Filter-Buttons
            config.onDropdownOpen = function(dropdown) {
                const filters = dropdown.querySelectorAll('.status-filter');
                filters.forEach(filter => {
                    filter.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        currentFilter = e.currentTarget.dataset.status;
                        
                        // Aktiven Filter hervorheben
                        filters.forEach(f => {
                            f.classList.toggle('bg-gray-100', f.dataset.status !== currentFilter);
                            f.classList.toggle('bg-gray-300', f.dataset.status === currentFilter);
                        });

                        // Optionen löschen und neu laden
                        this.clear();
                        this.clearOptions();
                        this.focus();
                    });
                });
            };

            // Modifiziere die load-Funktion für Jobs
            config.load = (query, callback) => {
                if (!query.length) return callback();
                let url = `/backend/search?term=${encodeURIComponent(query)}&model=${model}`;
                
                // Füge active Parameter nur für Jobs hinzu, wenn nicht "alle" ausgewählt ist
                if (model === 'jobs' && currentFilter !== 'all') {
                    url += `&active=${currentFilter === 'active'}`;
                }
                
                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        // Filtere die Ergebnisse nur für Jobs basierend auf dem aktuellen Filter
                        const filteredJson = model === 'jobs' && currentFilter !== 'all'
                            ? json.filter(item => 
                                (currentFilter === 'active' && item.active) || 
                                (currentFilter === 'inactive' && !item.active)
                            )
                            : json;
                            
                        const mappedJson = filteredJson.map(item => ({
                            ...item,
                            status: item.active ? 'active' : 'inactive',
                            tags: item.active ? ['Aktiv'] : ['Inaktiv']
                        }));
                        
                        callback(mappedJson);
                    }).catch(() => {
                        callback();
                    });
            };
        } else {
            config.render = {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '".</div>';
                },
                option: function(data, escape) {
                    let subtext = data.subtext ? '<br/><span class="subtext text-xs text-gray-400 truncate">' + data.subtext + '</span>' : '';
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span>' +
                        subtext +
                        '</div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                }
            };

            // Füge die load-Funktion auch für andere Modelle hinzu
            config.load = (query, callback) => {
                if (!query.length) return callback();
                let url = `/backend/search?term=${encodeURIComponent(query)}&model=${model}`;
                
                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                    }).catch(() => {
                        callback();
                    });
            };
        }

        this.tomselect = new TomSelect(this.inputTarget, config);
    }

    destroyTomSelect() {
        this.tomselect.destroy();
    }
}