import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
    static targets = ["input"]
    static values = {
        colors: Object
    }

    connect() {
        this.initializeColoredTomSelect();
    }

    disconnect() {
        if (this.tomselect) {
            this.tomselect.destroy();
        }
    }

    initializeColoredTomSelect() {
        
        this.tomselect = new TomSelect(this.inputTarget, {
            valueField: 'id',
            labelField: 'text',
            searchField: 'text',
            multiple: false,
            maxItems: 1, // Nur ein Element auswählbar
            allowEmptyOption: true, // Erlaubt leere Auswahl
            render: {
                option: (data, escape) => {
                    const color = this.colorsValue[data.id] || '#E5E7EB'; // Default color if null

                    return `<div class="flex items-center p-2">
                        <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium" 
                              style="background-color: ${color}">
                            ${escape(data.text)}
                        </span>
                    </div>`;
                },
                item: (data, escape) => {
                    const color = this.colorsValue[data.id] || '#E5E7EB'; // Default color if null
                    return `<div class="flex items-center">
                        <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium -mt-1" 
                              style="background-color: ${color}">
                            ${escape(data.text)}
                        </span>
                    </div>`;
                }
            }
        });
    }

    initializeDefaultTomSelect() {
        this.tomselect = new TomSelect(this.inputTarget, {
            valueField: 'id',
            labelField: 'text',
            searchField: 'text',
            placeholder: this.inputTarget.dataset.placeholder || "Suchen"
        });
    }
} 