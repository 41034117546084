import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addStage(event) {
    event.preventDefault()
    
    const stagesContainer = this.element.querySelector('.space-y-4')
    const stageCount = stagesContainer.children.length
    
    const newStage = `
      <div class="flex items-center justify-between py-2 border-b" data-controller="color-picker">
        <div class="grid grid-cols-6 gap-4 items-center w-full">
          <div class="col-span-2">
            <input type="text" name="setting[pipeline_stages[${stageCount}][name]]" class="tw-form-control" placeholder="Name der Stufe">
          </div>
          <div class="col-span-1">
            <div class="relative">
              <input type="text" name="setting[pipeline_stages[${stageCount}][icon]]" class="tw-form-control" placeholder="fas fa-star">
              <div class="absolute right-2 top-2">
                <i class="fas fa-star"></i>
              </div>
            </div>
          </div>
          <div class="col-span-1">
            <input type="text" name="setting[pipeline_stages[${stageCount}][color]]" class="tw-form-control" value="#000000" data-color-picker-target="input" data-action="input->color-picker#updateColor">
          </div>
          <div class="col-span-1">
            <select name="setting[pipeline_stages[${stageCount}][stage_type]]" class="tw-form-control">
              <option value="active">Aktiv</option>
              <option value="won">Gewonnen</option>
              <option value="lost">Verloren</option>
              <option value="archived">Archiviert</option>
            </select>
          </div>
          <div class="col-span-1">
            <input type="number" name="setting[pipeline_stages[${stageCount}][position]]" class="tw-form-control" min="1" value="${stageCount + 1}">
          </div>
        </div>
      </div>
    `
    
    stagesContainer.insertAdjacentHTML('beforeend', newStage)
  }
} 