import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scoreOptions", "rangeOptions", "selectOptions", "booleanOptions"]

  connect() {
    this.toggleOptions()
  }

  toggleOptions() {
    const fieldTypeSelect = this.element.querySelector('select[name="custom_property_definition[field_type]"]')
    if (!fieldTypeSelect) return

    const fieldType = fieldTypeSelect.value
    
    // Hide all option sections first
    this.hideAllOptions()
    
    // Show specific options based on field type
    if (fieldType === 'score') {
      this.showScoreOptions()
    } else if (fieldType === 'range') {
      this.showRangeOptions()
    } else if (fieldType === 'select') {
      this.showSelectOptions()
    } else if (fieldType === 'boolean') {
      this.showBooleanOptions()
    }
  }

  hideAllOptions() {
    if (this.hasScoreOptionsTarget) {
      this.scoreOptionsTarget.classList.add('hidden')
    }
    if (this.hasRangeOptionsTarget) {
      this.rangeOptionsTarget.classList.add('hidden')
    }
    if (this.hasSelectOptionsTarget) {
      this.selectOptionsTarget.classList.add('hidden')
    }
    if (this.hasBooleanOptionsTarget) {
      this.booleanOptionsTarget.classList.add('hidden')
    }
  }

  showScoreOptions() {
    if (this.hasScoreOptionsTarget) {
      this.scoreOptionsTarget.classList.remove('hidden')
    }
  }
  
  showRangeOptions() {
    if (this.hasRangeOptionsTarget) {
      this.rangeOptionsTarget.classList.remove('hidden')
    }
  }
  
  showSelectOptions() {
    if (this.hasSelectOptionsTarget) {
      this.selectOptionsTarget.classList.remove('hidden')
    }
  }
  
  showBooleanOptions() {
    if (this.hasBooleanOptionsTarget) {
      this.booleanOptionsTarget.classList.remove('hidden')
    }
  }
} 