import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["prompt", "submit", "result", "output"]

  generate(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    this.submitTarget.disabled = true

    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.outputTarget.textContent = data.text
        this.resultTarget.classList.remove('hidden')
      } else {
        console.error(data.error)
      }
    })
    .catch(error => console.error(error))
    .finally(() => {
      this.submitTarget.disabled = false
    })
  }

  apply(event) {
    const generatedText = this.outputTarget.textContent
    const targetField = document.querySelector(`[name="profile[${this.targetFieldValue}]"]`)
    if (targetField) {
      targetField.value = generatedText
    }
  }
} 