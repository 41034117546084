//https://github.com/chrislabarge/color-picker-demo

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    connect() {
        this.initColorPicker()
    }

    initColorPicker() {
        // Hier können wir später einen richtigen Color-Picker integrieren
        // Für jetzt verwenden wir ein einfaches input[type="color"]
        const input = this.inputTarget
        input.type = "color"
        input.value = input.value || "#000000"
    }

    updateColor(event) {
        const color = event.target.value
        event.target.style.backgroundColor = color
    }
}