import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  connect() {
    // Optional: Speichern des Zustands in localStorage
    const isOpen = localStorage.getItem('requisitionFormOpen') === 'true'
    if (isOpen) {
      this.open()
    }
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    this.iconTarget.classList.toggle('rotate-180')
    
    // Optional: Speichern des Zustands in localStorage
    localStorage.setItem('requisitionFormOpen', !this.contentTarget.classList.contains('hidden'))
  }

  open() {
    this.contentTarget.classList.remove('hidden')
    this.iconTarget.classList.add('rotate-180')
  }

  close() {
    this.contentTarget.classList.add('hidden')
    this.iconTarget.classList.remove('rotate-180')
  }
} 