import { Modal } from "tailwindcss-stimulus-components"
import TomSelect from "tom-select"

export default class extends Modal {

    open(e) {
        super.open(e);

        // set focus to the first input element in the modal.
        let input = this.element.querySelector('input');
        // if there is a tomselect element, output the first one to the console
        let tomselect = this.element.querySelector('.ts-control');
        
        if (tomselect) {
            // get the existing tomselect instance
            tomselect.focus();
        } else if (input) {
            input.focus();
        }
    }
}