import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox"]

  connect() {
    this.updateSelectAllCheckbox()
  }

  toggleAll(event) {
    const checked = event.target.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = checked
      checkbox.dispatchEvent(new Event('change'))
    })
  }

  toggleOne() {
    this.updateSelectAllCheckbox()
  }

  updateSelectAllCheckbox() {
    if (this.hasCheckboxAllTarget) {
      const totalCheckboxes = this.checkboxTargets.length
      const checkedCheckboxes = this.checkboxTargets.filter(c => c.checked).length

      this.checkboxAllTarget.checked = totalCheckboxes === checkedCheckboxes
      this.checkboxAllTarget.indeterminate = checkedCheckboxes > 0 && checkedCheckboxes < totalCheckboxes
    }
  }
} 