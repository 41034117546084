import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stepsContainer"]

  addStep() {
    const stepsCount = this.stepsContainerTarget.querySelectorAll('.workflow-step').length
    const newStepHtml = `
      <div class="workflow-step min-w-80 bg-default border-subtle flex w-full rounded-md border p-7 mt-3">
        <div class="w-full">
          <div class="flex">
            <div class="w-full">
              <div class="flex">
                <div class="bg-subtle text-default flex h-5 w-5 items-center justify-center rounded-full p-1 text-xs ltr:mr-5 rtl:ml-5 mt-[3px]">
                  ${stepsCount + 3}
                </div>
                <div>
                  <div class="text-emphasis text-base font-bold">Aktion</div>
                  <div class="text-default text-sm">Eine Aktion wird ausgeführt</div>
                </div>
              </div>
            </div>
          </div>
          <div class="border-subtle my-7 border-t"></div>
          <div>
            <label class="text-emphasis mb-2 block text-sm font-medium">Mache dies</label>
            <select class="bg-default border-default text-sm leading-4 placeholder:text-sm placeholder:font-normal dark:focus:border-emphasis focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-default hover:border-emphasis rounded-md border py-2 px-3 h-fit !min-h-9 w-full">
              <option value="email">E-Mail an Bewerber senden</option>
              <option value="status">Status ändern</option>
              <option value="notify">Team benachrichtigen</option>
            </select>
          </div>
        </div>
      </div>
    `
    this.stepsContainerTarget.insertAdjacentHTML('beforeend', newStepHtml)
  }
} 