import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

export default class extends Controller {
  static targets = ["column", "content", "toggle"]
  static values = {
    collapsed: { type: Boolean, default: false }
  }

  connect() {
    this.initializeSortable()
    // Setze "Kein Status" Spalte standardmäßig auf zugeklappt
    const emptyStatusColumn = this.element.querySelector('[data-status=""]')
    if (emptyStatusColumn) {
      const content = emptyStatusColumn.querySelector('[data-kanban-target="content"]')
      const toggle = emptyStatusColumn.querySelector('[data-kanban-target="toggle"]')
      if (content && toggle) {
        content.classList.add('hidden')
        toggle.classList.remove('fa-chevron-down')
        toggle.classList.add('fa-chevron-right')
      }
    }
  }

  toggleColumn(event) {
    const column = event.currentTarget.closest('[data-kanban-target="column"]')
    const content = column.querySelector('[data-kanban-target="content"]')
    const toggle = column.querySelector('[data-kanban-target="toggle"]')
    
    content.classList.toggle('hidden')
    toggle.classList.toggle('fa-chevron-down')
    toggle.classList.toggle('fa-chevron-right')
  }

  initializeSortable() {
    const sortable = new Sortable(this.columnTargets, {
      draggable: '.kanban-card',
      handle: '.kanban-card-handle',
      mirror: {
        constrainDimensions: true
      },
      delay: 0,
      classes: {
        'source:dragging': 'opacity-50'
      }
    })

    sortable.on('sortable:stop', async (event) => {
      const cardId = event.data.dragEvent.source.dataset.requisitionId
      const newStatus = event.data.newContainer.dataset.status
      const card = event.data.dragEvent.source
      const newContainer = event.data.newContainer.querySelector('.p-2')
      const oldContainer = event.oldContainer
      
      newContainer.appendChild(card)
      
      try {
        const token = document.querySelector('meta[name="csrf-token"]').content
        const formData = new FormData()
        formData.append('requisition[status]', newStatus)
        
        const response = await fetch(`/backend/requisitions/${cardId}/update_status`, {
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': token,
            'Accept': 'application/json'
          },
          body: formData
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        this.showNotification('Status erfolgreich aktualisiert', 'success')
        this.updateColumnCounters(event.data.oldContainer, event.data.newContainer)

      } catch (error) {
        console.error('Fehler:', error)
        oldContainer.appendChild(card)
      }
    })
  }

  updateColumnCounters(oldColumn, newColumn) {
    const oldCounter = oldColumn.querySelector('.text-gray-600.rounded-full')
    if (oldCounter) {
      oldCounter.textContent = oldColumn.querySelectorAll('.kanban-card').length
    }

    const newCounter = newColumn.querySelector('.text-gray-600.rounded-full')
    if (newCounter) {
      newCounter.textContent = newColumn.querySelectorAll('.kanban-card').length
    }
  }

  showNotification(message, type) {
    const notification = document.createElement('div')
    notification.className = `flash flash-${type} fixed top-4 right-4 p-4 rounded shadow-lg z-50`
    notification.textContent = message
    document.body.appendChild(notification)
    
    notification.style.animation = 'fadeInOut 3s ease-in-out'
    
    setTimeout(() => {
      notification.remove()
    }, 3000)
  }
}